/* You can add global styles to this file, and also import other style files */

* {
    font-family: Aeonik, sans-serif
}

body {
    background: #FFFBF8;
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #4D4D4D;
    margin: 0;
    padding: 0;
}

::selection {
    background: #FFE6D5;
    color: #272338;
}

::-moz-selection {
    background: #FFE6D5;
    color: #272338;
}


img {
    height: auto;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar:horizontal {
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 24px;
    background-color: #999999;
    transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
        //background-color: #808080;
        background-color: #7093FF;
    }
}

::-webkit-scrollbar-track {
    border-radius: 24px;
    background-color: #E6E6E6;
    border: none;
}

a,
button {
    -webkit-transition: all 0.35s ease 0s;
    transition: all 0.35s ease 0s;
    text-decoration: none;
}

.container {
    //max-width: 1384px;
    max-width: 1440px;
    padding-left: 40px;
    padding-right: 40px;

    @media screen and (max-width: 1280px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.primary-btn {
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    background: #7093FF;
    border: 1px solid #272338;
    border-radius: 16px;
    padding: 11px 24px;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    gap: 8px;
    justify-content: center;
    align-items: center;
    display: inline-flex;

    &:hover {
        background: #272338;
        color: #fff;
        border-color: #272338;
    }

    &[disabled] {
        background: #F5F5F5;
        color: #CCCCCC;
        cursor: not-allowed;
    }
}

.secondary-btn {
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #272338;
    text-align: center;
    background: #fff;
    border: 1px solid #272338;
    border-radius: 16px;
    padding: 11px 24px;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    gap: 8px;
    justify-content: center;
    align-items: center;
    display: inline-flex;

    &:hover {
        background: #A9BEFF;
        color: #272338;
        border-color: #272338;
    }

    &[disabled] {
        background: #F2F2F2;
        color: rgba(97, 97, 97, 0.5);
        border-color: rgba(97, 97, 97, 0.5);
        cursor: not-allowed;
    }
}

.section-title {
    font-family: Livorno, serif;
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    color: #272338;
    letter-spacing: -0.01em;
    margin: 0 0 8px;

    @media screen and (max-width: 991px) {
        font-size: 32px;
        line-height: 38px;
    }
}

.section-semi-title {
    font-family: Livorno, serif;
    font-size: 26px;
    line-height: 31px;
    font-weight: 400;
    letter-spacing: -0.01em;
    color: #272338;
    margin: 0 0 16px;
}

.section-info {
    font-family: Aeonik, sans-serif;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #333333;
    margin: 0;

    @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 22px;
    }
}

//======= Custom card Css Start =======
.card-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
    margin: 0 -6px;
    word-break: break-word;

    @media screen and (max-width: 1099px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.card-main {
    padding: 6px;
    border: 1px solid transparent;
    background: transparent;
    border-radius: 20px 20px 10px 10px;
    position: relative;
    transition: all 0.35s ease 0s;
    word-break: break-word;
    overflow: hidden;

    .slider-custom {
        border: 1px solid #272338;
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        margin: 0 0 8px;
        transition: all 0.35s ease 0s;

        .featured-label {
            position: absolute;
            left: 14px;
            top: 14px;
            font-family: Aeonik, sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            padding: 3px 9px;
            color: #FFFFFF;
            background: #661735;
            border: 1px solid #272338;
            border-radius: 16px;
            z-index: 2;
        }

        .local-label {
            position: absolute;
            left: 14px;
            top: 14px;
            font-family: Aeonik, sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            padding: 3px 9px;
            color: #272338;
            background: #A8AED6;
            border: 1px solid #272338;
            border-radius: 16px;
            z-index: 2;
        }

        .heart-icon {
            position: absolute;
            right: 14px;
            top: 14px;
            border: 1px solid #272338;
            background: #FFFFFF;
            border-radius: 50%;
            padding: 0;
            opacity: 0;
            z-index: 2;

            &:hover {
                background: #A9BEFF;
            }
        }

        .image-box {
            display: block;
            width: 100%;
            //height: 250px;
            position: relative;
            padding-bottom: 100%;
            min-height: 320px;

            @media screen and (max-width: 1440px) {
                min-height: 318px;
            }

            @media screen and (max-width: 1366px) {
                min-height: 298px;
            }

            @media screen and (max-width: 1280px) {
                min-height: 280px;
            }

            @media screen and (max-width: 1200px) {
                min-height: 270px;
            }

            @media screen and (max-width: 1199px) {
                min-height: 228px;
            }

            @media screen and (max-width: 991px) {
                min-height: 240px;
            }

            @media screen and (max-width: 767px) {
                min-height: 317px;
            }

            @media screen and (max-width: 640px) {
                min-height: 304px;
            }

            @media screen and (max-width: 575px) {
                min-height: 265px;
            }

            @media screen and (max-width: 480px) {
                min-height: 272px;
            }

            @media screen and (max-width: 360px) {
                min-height: 261px;
            }

            @media screen and (max-width: 359px) {
                min-height: 230px;
            }

            img {
                object-fit: cover;
                object-position: center center;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        .slider-inner {
            .splide__arrow {
                z-index: 2;
                background-color: #FFFFFF;
                border: 1px solid #272338;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                opacity: 0;
                outline: none;

                &.splide__arrow--prev {
                    background-image: url("/assets/images/arrow-left.svg");
                }

                &.splide__arrow--next {
                    background-image: url("/assets/images/arrow-right.svg");
                }

                &:hover {
                    background-color: #A9BEFF;
                }

                svg {
                    display: none;
                }
            }

            .splide__pagination {
                padding: 0;
                position: absolute;
                top: auto;
                left: 16px;
                bottom: 16px;
                right: 16px;
                z-index: 2;
                gap: 8px;
                align-items: center;
                display: flex;

                li {
                    display: inline-flex;

                    .splide__pagination__page {
                        background: rgba(255, 255, 255, 0.8);
                        border: 1px solid transparent;
                        opacity: 1;
                        margin: 0;
                        width: 6px;
                        height: 6px;
                        transform: none;
                        transition: all 0.25s ease 0s;

                        &.is-active {
                            background: #FFFFFF;
                            border: 1px solid #272338;
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }
        }
    }

    .card-details {
        .card-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            width: 100%;

            .title {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #272338;
                margin: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
                width: calc(100% - 50px);
            }
        }

        .right-side-rating {
            display: flex;
            align-items: center;
            gap: 6px;

            .rating {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #272338;
            }

            .new {
                background: #94B3A5;
                border: 1px solid #272338;
                padding: 3px 9px;
                font-family: Aeonik, sans-serif;
                font-size: 12px;
                line-height: 14px;
                font-weight: 500;
                text-align: center;
                color: #272338;
                border-radius: 16px;
            }
        }

        .card-label {
            font-family: Aeonik, sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            color: #808080;
            display: block;
            margin: 0 0 4px;
            text-transform: uppercase;
        }

        .card-title-inner {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            color: #272338;
            margin: 0 0 4px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            position: relative;
            padding-right: 24px;
            max-width: fit-content;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 16px;
                bottom: 0;
                margin: auto;
                width: 13px;
                height: 10px;
                background-image: url(/assets/images/arrow-right-icon.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                transition: all 0.35s ease 0s;
                opacity: 0;
                display: inline-flex;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                height: 1px;
                background: #272338;
                width: 0;
                transition: all 0.35s ease 0s;
                opacity: 0;
            }
        }

        .card-dec {
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            margin: 0 0 12px;
            color: #4D4D4D;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            //min-height: 55px;

            @media screen and (max-width: 640px) {
                min-height: inherit;
            }

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin: 0;
            }
        }

        .card-category {
            gap: 4px;
            align-items: center;
            //flex-wrap: wrap;
            display: flex;
            position: relative;
            width: 100%;

            .secondary-btn {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                text-align: center;
                color: #272338;
                padding: 7px 12px;
                border-radius: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                max-width: 55%;

                &:hover {
                    background: #FFE6D5;
                }
            }

            .care-more-category {
                font-family: Aeonik, sans-serif;
                //font-size: 16px;
                //line-height: 22px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #4D4D4D;
                padding: 0 2px;
                white-space: nowrap;

                .care-more-category-box {
                    position: absolute;
                    right: 8px;
                    background: rgba(39, 35, 56, 0.8);
                    backdrop-filter: blur(5px);
                    padding: 10px;
                    border-radius: 16px;
                    max-width: calc(100% - 16px);
                    gap: 8px;
                    bottom: calc(100% + 24px);
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.35s ease 0s;
                    flex-wrap: wrap;
                    justify-content: center;
                    display: flex;
                    z-index: 4;
                    white-space: normal;

                    &:before {
                        content: '';
                        height: 10px;
                        width: 16px;
                        border-radius: 0;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -10px;
                        margin: auto;
                        border-style: solid;
                        border-width: 10px 8px 0 8px;
                        border-color: rgba(39, 35, 56, 0.8) transparent transparent transparent;
                    }

                    .secondary-btn {
                        max-width: 100%;
                    }
                }

                &:hover {
                    .care-more-category-box {
                        bottom: calc(100% + 12px);
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .address {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #4D4D4D;
        display: block;
        margin: 0 0 2px;

        &:last-child {
            margin-bottom: 0;
        }

        + .card-category {
            margin-top: 8px;
        }
    }

    .card-footer {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;
            color: #4D4D4D;

            &.dot {
                font-size: 20px;
                font-weight: 700;
                color: #999999;
            }
        }
    }

    &:hover {
        //background: #A9BEFF33;
        //border: 1px solid #A9BEFF99;
        background: rgba(169, 190, 255, 0.2);
        border: 1px solid rgba(169, 190, 255, 0.6);

        .slider-custom {
            border-color: #7093FF;
            box-shadow: 0 0 0 1px #7093FF;

            .heart-icon {
                opacity: 1;
            }

            .slider-inner {
                .splide__arrow {
                    opacity: 1;
                }
            }
        }

        .card-details {
            .card-title-inner {

                //text-decoration: underline;
                &:after {
                    right: 2px;
                    opacity: 1;
                }

                &:before {
                    width: calc(100% - 24px);
                    opacity: 1;
                }
            }
        }
    }
}

.blogs-list {
    .card-main {
        .slider-custom {
            .image-box {
                padding-bottom: 77.34%;
                min-height: initial;
            }
        }
    }
}

.see-more {
    margin: 32px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .secondary-btn {
        gap: 8px;

        img {
            animation: arrowMove 2s infinite;
        }

        @keyframes arrowMove {

            0%,
            20%,
            50%,
            80%,
            100% {
                transform: translateY(0);
            }

            40% {
                transform: translateY(-10px);
            }

            60% {
                transform: translateY(-5px);
            }
        }
    }
}

//======= Custom card Css END =======

//======= Input CSS Start =======
.form-group {
    margin-bottom: 16px;
    position: relative;

    > label {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #272338;
        margin-bottom: 4px;
        vertical-align: top;
        display: inline-block;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
        }

        .optional {
            color: #4D4D4D;
            font-weight: 400;
        }

        small {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: #4D4D4D;

            @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    .req-lbl {
        color: #D24646;
        font-weight: bold;
    }

    .form-control {
        background: #fff;
        padding: 9px 17px;
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #272338;
        border: 1px solid #272338;
        border-radius: 16px;
        box-shadow: none;
        transition: all 0.35s ease 0s;

        @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        &:focus {
            border-color: #7093FF;
            box-shadow: 0 0 0 1px #7093FF;
        }

        &.error {
            border-color: #D24646;
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            color: #808080;
            font-weight: 400;
        }

        &::-moz-placeholder {
            color: #808080;
            font-weight: 400;
        }

        &:-ms-input-placeholder {
            color: #808080;
            font-weight: 400;
        }

        &:-moz-placeholder {
            color: #808080;
            font-weight: 400;
        }

        &[type=search] {
            &::-webkit-search-cancel-button {
                margin-left: 8px;
                filter: brightness(0);
            }
        }
    }

    textarea {
        &.form-control {
            height: 127px;
            resize: none;

            &:hover,
            &:focus {
                resize: vertical;
            }
        }
    }

    .error:not(.form-control) {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin-top: 4px;
        color: #D24646;
    }

    .custom-radio {
        font-weight: 400;
        display: inline-flex;
        margin-bottom: 0;
    }

    .mobile-no-input {
        z-index: 2;
        position: relative;
        display: flex;

        .form-control-ng-select {
            .ng-select-container {
                border-radius: 16px 0 0 16px !important;
                border-right: none;
                min-width: 75px;

                .ng-clear-wrapper {
                    display: none;
                }
            }

            .ng-dropdown-panel {
                min-width: 230px;

                .ng-dropdown-panel-items {
                    .ng-option {
                        white-space: normal;
                    }
                }
            }
        }

        .form-control {
            border-radius: 0 16px 16px 0;
        }
    }

    .password-input {
        position: relative;

        .form-control {
            padding-right: 44px;
        }

        .password-btn {
            position: absolute;
            top: 0;
            right: 15px;
            bottom: 0;
            height: 20px;
            margin: auto 0;
            padding: 0;
            border: 0;
            border-radius: 0;
            background: transparent;
            cursor: pointer;
            box-shadow: none;
            display: inline-flex;
        }
    }

    .form-icon-input {
        position: relative;

        img {
            position: absolute;
            top: 8px;
            left: 13px;
            pointer-events: none;

            @media screen and (max-width: 767px) {
                top: 10px;
            }
        }

        .form-control {
            padding-left: 45px;
        }
    }

    .recaptcha-box{
        @media screen and (max-width: 359px) {
            transform: scale(0.9);
            transform-origin: left top;
            margin-bottom: -7px;
        }
    }
}

.custom-radio {
    cursor: pointer;
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #272338;
    font-weight: 400;
    padding-left: 28px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 20px;
    align-items: center;
    display: inline-flex;

    @media screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
        padding-left: 36px;
        min-height: 28px;
    }

    input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        opacity: 0;
        width: 20px;
        height: 20px;
        border-radius: 30px;
        border: none;
        outline: none;
        padding: 0;

        @media screen and (max-width: 767px) {
            width: 28px;
            height: 28px;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 30px;
        border: 1px solid #272338;
        transition: all 0.3s ease 0s;

        @media screen and (max-width: 767px) {
            width: 28px;
            height: 28px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 4px;
            height: 4px;
            border-radius: 2px;
            background: #fff;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease 0s;
            transform: rotate(45deg);
        }
    }

    &:hover {
        .checkmark {
            border-color: #7093FF;
        }
    }

    input:checked ~ .checkmark {
        background-color: #7093FF;
        border-color: #272338 !important;

        &:after {
            opacity: 1;
            visibility: visible;
            width: 8px;
            height: 8px;

            @media screen and (max-width: 767px) {
                width: 10px;
                height: 10px;
            }
        }
    }
}

.custom-radio-btn {
    .custom-radio {
        padding: 11px 11px 11px 44px;
        font-size: 16px;
        line-height: 20px;
        color: #222222;
        border-radius: 16px;
        border: 1px solid #272338;
        transition: all 0.3s ease 0s;

        input {
            left: 16px;
            width: 16px;
            height: 16px;
            margin: auto;
        }

        .checkmark {
            left: 16px;
            bottom: 0;
            height: 16px;
            width: 16px;
            margin: auto;

            &:after {
                width: 3px;
                height: 3px;
            }
        }

        &:hover {
            border-color: #7093FF;

            .checkmark {
                border-color: #7093FF;
            }
        }

        input:checked ~ .checkmark {
            &:after {
                width: 6px;
                height: 6px;
            }
        }

        &:has(input:checked) {
            border-color: #7093FF;
            background: #F1F5FF;
        }
    }
}

//======= Input CSS END=======

//======= NG Select CSS Start =======
.form-control-ng-select {
    &.ng-select {
        .ng-select-container {
            background: #fff;
            padding: 9px 40px 9px 0;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            border: 1px solid #272338;
            border-radius: 16px !important;
            box-shadow: none;
            transition: all 0.35s ease 0s;
            height: auto;
            min-height: 40px;

            @media screen and (max-width: 767px) {
                min-height: 44px;
            }

            .ng-value-container {
                //padding-left: 17px;
                padding: 0 0 0 17px;

                .ng-placeholder {
                    color: #808080;
                    font-weight: 400;
                    padding: 0;
                }

                .ng-input {
                    top: 9px;
                    //padding-left: 17px;
                    //padding-right: 40px;
                    padding: 0 40px 0 17px;
                }
            }

            .ng-arrow-wrapper {
                position: absolute;
                top: 0;
                right: 16px;
                bottom: 0;
                margin: auto;
                padding: 0;
                background-image: url(/assets/images/chevron-down-icon.svg);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
                width: 12px;
                height: 8px;
                transition: all 0.35s ease 0s;

                .ng-arrow {
                    display: none;
                }
            }

            .ng-clear-wrapper {
                color: #272338;
                font-size: 20px;
                line-height: 14px;
                width: 14px;
                padding-bottom: 2px;
                justify-content: flex-end;
                display: inline-flex;

                .ng-clear {
                    font-size: inherit;
                    line-height: inherit;
                    color: inherit;
                    margin: 0;
                }
            }
        }

        &.ng-select-multiple {
            .ng-select-container {
                padding-top: 7px;
                padding-bottom: 7px;

                .ng-value-container {
                    gap: 4px;

                    .ng-placeholder {
                        top: 9px;
                        left: 17px;
                    }

                    .ng-input {
                        padding: 0;
                    }

                    .ng-value {
                        margin: 0;
                        background: #272338;
                        border: none;
                        border-radius: 16px;
                        padding: 4px 26px 4px 9px;
                        font-family: Aeonik, sans-serif;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 500;
                        color: #ffffff;
                        position: relative;
                        flex-direction: row-reverse;
                        display: inline-flex;

                        .ng-value-label {
                            padding: 0;
                            font-size: inherit;
                            line-height: inherit;
                            font-family: inherit;
                        }

                        .ng-value-icon {
                            padding: 0;
                            border: none;
                            font-size: 0;
                            width: 12px;
                            height: 12px;
                            position: absolute;
                            top: 0;
                            right: 8px;
                            bottom: 1px;
                            margin: auto;
                            cursor: pointer;
                            background: transparent;

                            &:before,
                            &:after {
                                content: "";
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                margin: auto;
                                left: 0;
                                transform: rotate(45deg);
                                transition: all 0.35s ease 0s;
                                background: #fff;
                            }

                            &:before {
                                width: 11px;
                                height: 1px
                            }

                            &:after {
                                width: 1px;
                                height: 11px;
                            }
                        }
                    }
                }
            }
        }

        &.ng-select-opened {
            .ng-select-container {
                border-color: #7093FF;
                box-shadow: 0 0 0 1px #7093FF;

                .ng-arrow-wrapper {
                    transform: rotateX(180deg);
                }
            }
        }

        &.ng-select-focused {
            &:not(.ng-select-opened) {
                .ng-select-container {
                    border-color: #272338;
                    box-shadow: none;
                }
            }
        }
    }

    &.ng-dropdown-panel,
    .ng-dropdown-panel {
        border: 1px solid #272338;
        border-radius: 16px;
        padding: 0;
        box-shadow: none;
        margin: 4px 0;
        overflow: hidden;

        .ng-dropdown-panel-items {
            padding: 18px 24px;

            .ng-option {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #272338;
                padding: 0;
                background: transparent !important;
                margin: 0 0 16px;
                transition: all 0.35s ease 0s;

                &:last-child {
                    margin-bottom: 0;
                }

                &:not(.ng-option-disabled):hover {
                    color: #7093FF;
                    background: transparent !important;
                }

                &.ng-option-selected {
                    color: #7093FF;
                    font-weight: 700;

                    .ng-option-label {
                        color: #7093FF;
                        font-weight: 700;
                    }
                }

                &.ng-option-disabled {

                    cursor: default;

                    color: #808080;

                }
            }

            .ng-optgroup {
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;
                color: #999999;
                margin: 0 0 10px;
                padding: 0;
                text-transform: uppercase;
                background: transparent;
            }
        }
    }

    &.option-ng-select {
        &.ng-dropdown-panel,
        .ng-dropdown-panel {
            .ng-dropdown-panel-items {
                padding: 16px;

                > div {
                    gap: 8px;
                    flex-wrap: wrap;
                    display: flex;

                    .ng-optgroup {
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }

                .ng-option {
                    border-radius: 24px;
                    margin: 0;
                    padding: 6px 12px;
                    border: 1px solid #272338;
                    cursor: pointer;
                    white-space: normal;
                    box-shadow: none;

                    &:not(.ng-option-disabled), &.ng-option-marked, &:not(.ng-option-selected) {
                        &:hover {
                            background: #FFE6D5 !important;
                            border-color: #272338;
                            color: #272338;
                        }
                    }

                    &.ng-option-selected {
                        background: #272338 !important;
                        border-color: #272338;
                        color: #fff;
                        font-weight: 500;

                        .ng-option-label {
                            color: #fff;
                            font-weight: 500;
                        }

                        &:not(.ng-option-disabled), &.ng-option-marked, &:not(.ng-option-selected) {
                            &:hover {
                                background: #272338 !important;
                                border-color: #272338;
                                color: #272338;
                            }
                        }
                    }

                    &.ng-option-disabled {
                        width: 100%;
                        padding: 0;
                        border-radius: 0;
                        border: none;
                        text-align: left;
                    }
                }

                .ng-optgroup {
                    width: 100%;
                    margin-bottom: 0;
                    margin-top: 8px;
                    color: #272338;
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }
}

//.form-control-ng-select {
//    &.ng-dropdown-panel,
//    .ng-dropdown-panel {
//        border: 1px solid #272338;
//        border-radius: 16px;
//        padding: 0;
//        box-shadow: none;
//        margin: 4px 0;
//        overflow: hidden;
//
//        .ng-dropdown-panel-items {
//            padding: 18px 24px;
//
//            .ng-option {
//                font-family: Aeonik, sans-serif;
//                font-size: 14px;
//                line-height: 18px;
//                font-weight: 500;
//                color: #272338;
//                padding: 0;
//                background: transparent !important;
//                margin: 0 0 16px;
//                transition: all 0.35s ease 0s;
//
//                &:last-child {
//                    margin-bottom: 0;
//                }
//
//                &:not(.ng-option-disabled):hover {
//                    color: #7093FF;
//                    background: transparent !important;
//                }
//
//                &.ng-option-selected {
//                    color: #7093FF;
//                    font-weight: 700;
//
//                    .ng-option-label {
//                        color: #7093FF;
//                        font-weight: 700;
//                    }
//                }
//
//                &.ng-option-disabled {
//
//                    cursor: default;
//
//                    color: #808080;
//
//                }
//            }
//
//            .ng-optgroup {
//                font-family: Aeonik, sans-serif;
//                font-size: 14px;
//                line-height: 18px;
//                font-weight: 500;
//                color: #999999;
//                margin: 0 0 10px;
//                padding: 0;
//                text-transform: uppercase;
//                background: transparent;
//            }
//        }
//    }
//}

//======= NG Select CSS END =======

//======= Bs-Date Picker CSS Start =======
.bs-datepicker {
    //box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    font-family: Aeonik, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    border: 1px solid #E6E6E6;
    border-radius: 12px;
    box-shadow: none;
    margin: 8px 0;
    background: #fff;

    .bs-datepicker-container {
        padding: 8px;

        .bs-media-container {
            display: flex;
            gap: 8px;
        }

        bs-days-calendar-view,
        bs-month-calendar-view,
        bs-years-calendar-view {
            border: 1px solid #272338;
            border-radius: 12px;
        }

        .bs-datepicker-head {
            background-color: #7093FF;
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            border-radius: 11px 11px 0 0;

            button {
                font-family: Aeonik, sans-serif;
                font-size: 16px;
                line-height: 22px;
                font-weight: 500;
                border-radius: 12px;
                transition: all 0.35s ease 0s;

                &:hover {
                    background-color: #272338;
                }

                &.previous,
                &.next {
                    background-repeat: no-repeat;
                    background-position: center center;

                    span {
                        display: none;
                    }
                }

                &.previous {
                    background-image: url("/assets/images/left-button-icon.svg");
                }

                &.next {
                    background-image: url("/assets/images/right-button-icon.svg");
                }
            }
        }

        .bs-datepicker-body {
            border-radius: 0 0 12px 12px;
            //border-color: #E6E6E6;
            border: none;

            table {
                th {
                    font-family: Aeonik, sans-serif;
                    font-size: 11px;
                    line-height: 16px;
                    font-weight: 500;
                    color: #272338;

                    span {
                        &.selected {
                            background-color: #7093FF;
                        }
                    }
                }

                td {
                    font-family: Aeonik, sans-serif;
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: 500;
                    color: #272338;

                    &:before {
                        left: -7px;
                        right: -7px;
                    }

                    &.is-highlighted:not(.disabled):not(.selected) {
                        span {
                            background-color: #A9BEFF;
                        }
                    }

                    span {
                        &:before {
                            left: -7px;
                            right: -7px;
                        }

                        &.selected {
                            background-color: #7093FF !important;
                            font-weight: 700;
                        }

                        &.is-highlighted:not(.disabled):not(.selected) {
                            background-color: #A9BEFF;
                        }
                    }
                }
            }
        }
    }
}

//======= Bs-Date Picker CSS End =======

//======= NGB-Date Picker CSS Start =======
.datepicker-wrap {
    background: #FFFFFF;
    border: 1px solid #272338;
    border-radius: 16px;
    overflow: hidden;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #272338;
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    right: 0;
    z-index: 9;

    ngb-datepicker {
        border: none;
        outline: none;
        width: 100%;
        display: block;
        border-radius: 16px;

        .ngb-dp-header {
            padding: 12px 14px;
            background: transparent;
            border: none;
            border-bottom: 1px solid #272338;
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            color: #272338;
            border-radius: 16px 16px 0 0;

            ngb-datepicker-navigation {
                .ngb-dp-arrow {
                    height: auto;
                    width: auto;

                    .ngb-dp-arrow-btn {
                        margin: 0;
                        width: 30px;
                        height: 30px;
                        background-color: transparent;
                        border-radius: 12px;
                        border: 1px solid transparent;
                        outline: none;
                        box-shadow: none;
                        padding: 0;
                        display: inline-flex;
                        vertical-align: middle;
                        background-repeat: no-repeat;
                        background-size: auto;
                        background-position: center center;
                        transition: all 0.35s ease 0s;

                        &:hover {
                            background-color: #A9BEFF;
                            border-color: #272338;
                        }

                        span,
                        .ngb-dp-navigation-chevron {
                            display: none;
                        }

                        &:disabled {
                            opacity: 0.45;
                        }
                    }

                    &.ngb-dp-arrow-next {
                        .ngb-dp-arrow-btn {
                            background-image: url("/assets/images/next-icon.svg");
                        }
                    }

                    &.ngb-dp-arrow-prev {
                        .ngb-dp-arrow-btn {
                            background-image: url("/assets/images/prev-icon.svg");
                        }
                    }
                }

                .ngb-dp-navigation-select {
                    display: flex;
                    gap: 6px;
                    flex: inherit;

                    .form-select {
                        padding: 0;
                        background: #fff;
                        appearance: none;
                        border: none;
                        border-radius: 0;
                        width: auto;
                        text-align: center;
                        height: auto;
                        min-width: inherit;
                        font-family: Aeonik, sans-serif;
                        font-size: 16px;
                        line-height: 21px;
                        font-weight: 500;
                        color: #272338;
                        box-shadow: none;
                        outline: none;
                        cursor: pointer;

                        option {
                            text-align: center;
                            padding: 4px 8px;
                        }

                        &:first-child {
                            text-align: right;
                        }

                        &:last-child {
                            text-align: left;
                        }
                    }
                }
            }
        }

        .ngb-dp-months {
            .ngb-dp-month {
                width: 100%;
                padding: 16px 24px;

                .ngb-dp-week {
                    padding: 0;
                    text-align: center;
                    justify-content: center;
                    margin: 0 0 10px;
                    display: flex;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &.ngb-dp-weekdays {
                        border: none;
                        border-radius: 0;
                        background: transparent;

                        .ngb-dp-weekday {
                            font-family: Aeonik, sans-serif;
                            font-size: 12px;
                            line-height: 14px;
                            font-weight: 500;
                            color: #666666;
                            font-style: normal;
                            width: auto;
                            height: auto;
                            flex: 1;
                        }
                    }

                    .ngb-dp-day {
                        font-family: Aeonik, sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #666666;
                        flex: 1;
                        width: auto;
                        height: auto;

                        &.disabled {
                            opacity: 0.45;
                        }

                        .custom-day {
                            width: 34px;
                            height: 34px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            background: transparent;
                            border: 1px solid transparent;
                            border-radius: 48px;
                            transition: all 0.35s ease 0s;

                            &:hover {
                                background: #7093FF;
                                color: #FFFFFF;
                            }

                            &.bg-primary {
                                border-color: #272338;
                                background: #661735 !important;
                                color: #FFFFFF;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    .datepicker-action {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #4D4D4D;
        padding: 16px 16px 16px 24px;
        border-top: 1px solid #272338;
        border-radius: 0 0 16px 16px;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
        display: flex;

        span {
            margin: 0 auto 0 0;
        }

        .secondary-btn,
        .primary-btn {
            font-size: 14px;
            line-height: 20px;
            padding: 9px 16px;
        }
    }
}

//======= NGB-Date Picker CSS Start =======

//======= Search Results Start =======
.search-results-not-found {
    padding: 52px 0;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: center;
    color: #333333;

    @media screen and (max-width: 767px) {
        padding: 16px 0;
    }

    strong {
        font-weight: 500;
        color: #272338;
    }

    p {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .emoji-icon {
        font-size: 36px;
        line-height: 50px;
    }

    .search-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin: 0 0 4px;
        color: #272338;
    }

    .clear-search-btn {
        outline: none;
        background: transparent;
        padding: 9px 16px;
        box-shadow: none;
        border: 1px solid transparent;
        border-radius: 16px;
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #7093FF;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;

        &:hover {
            background: #F1F5FF;
            color: #272338;
            border-color: #A9BEFF;
        }
    }
}

//======= Search Results END =======

//======= Browse other options Start =======
.browse-other-options {
    border-bottom: 1px solid #272338;
    padding: 32px 0;
    background: #FFF5EE;
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    text-align: center;
    color: #272338;

    p {
        margin-bottom: 0;
    }

    a {
        color: #272338;
        cursor: pointer;
        outline: none;
        text-decoration: none;

        &:hover {
            color: #7093FF;
        }
    }

    .section-semi-title {
        font-size: 32px;
        line-height: 38px;
        margin: 0 0 16px;

        @media screen and (max-width: 767px) {
            font-size: 26px;
            line-height: 31px;
        }

        span {
            display: block;
            font-family: Aeonik, sans-serif;
            font-size: 12px;
            line-height: 14px;
            font-weight: 500;
            color: #666666;
            text-transform: uppercase;
            margin: 0 0 2px;

            @media screen and (max-width: 767px) {
                margin: 0 0 8px;
            }
        }
    }
}

//======= Browse other options END =======

//======= Modal CSS Start =======
.modal-backdrop {
    background: rgba(34, 34, 34, 0.5);
    opacity: 1;

    &.show {
        opacity: 1;
    }
}

.modal {
    .modal-dialog {
        max-width: 434px;

        @media screen and (max-width: 767px) {
            margin: 0 auto;
            min-height: 100%;
            max-width: 100%;
        }

        &.modal-dialog-centered {
            @media screen and (max-width: 767px) {
                align-items: flex-end;
            }
        }
    }

    .modal-content {
        background: #FFFBF8;
        border-radius: 16px;
        border: 1px solid #272338;
        box-shadow: none;

        @media screen and (max-width: 767px) {
            border-radius: 0;
            border: none;
        }

        .close-btn {
            position: absolute;
            top: 16px;
            right: 16px;
            border: none;
            background: transparent;
            outline: none;
            padding: 0;
            cursor: pointer;
            box-shadow: none;
            width: 32px;
            height: 32px;
            align-items: center;
            justify-content: center;
            display: inline-flex;
            z-index: 2;

            @media screen and (max-width: 767px) {
                display: none;
            }

            img {
                transition: all 0.35s ease 0s;
            }

            &:hover {
                img {
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
        }

        .modal-header {
            border-radius: 16px 16px 0 0;
            border-bottom: 1px solid #272338;
            padding: 24px 32px 18px;
            position: relative;

            @media screen and (max-width: 767px) {
                border-radius: 0;
                padding: 24px 16px 18px;
                position: sticky;
                top: 0;
                background: #FFFBF8;
                z-index: 5;
                border-top: 1px solid #272338;
            }

            .modal-title {
                font-family: Aeonik, sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 25px;
                text-align: center;
                color: #272338;
                width: 100%;
                margin: 0;

                .modal-title-icon {
                    display: block;
                    text-align: center;
                    margin: 0 0 12px;
                }
            }
        }

        .modal-body {
            padding: 24px 32px;
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
            color: #272338;

            @media screen and (max-width: 767px) {
                padding: 24px 16px;
            }

            p {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .accept-checkbox {
                display: block;
                padding-top: 1px;
                padding-bottom: 1px;

                @media screen and (max-width: 767px) {
                    margin-top: 24px;
                }

                a {
                    font-weight: 700;
                    display: inline-block;
                    color: #7093FF;

                    &:hover {
                        color: #272338;
                    }
                }
            }

            .auth-mob-no {
                > label {
                    justify-content: space-between;
                    align-items: center;
                    display: flex;
                }
            }
        }

        .modal-footer {
            border-radius: 0 0 16px 16px;
            border-top: 1px solid #272338;
            padding: 24px 32px;
            gap: 8px;
            display: flex;

            @media screen and (max-width: 767px) {
                border-radius: 0;
                padding: 16px 16px 24px;
            }

            .primary-btn,
            .secondary-btn {
                width: 100%;
                margin: 0;

                @media screen and (min-width: 767px) {
                    font-size: 14px;
                    padding: 9px 16px;
                }
            }

            .secondary-btn {
                display: none;

                @media screen and (max-width: 767px) {
                    display: inline-flex;
                }
            }

            .have-account-link {
                font-family: Aeonik, sans-serif;
                width: 100%;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                text-align: center;
                margin: 10px 0 0;

                a,
                strong {
                    font-weight: 700;
                    display: inline-block;
                    color: #7093FF;
                    cursor: pointer;
                    transition: all 0.35s ease 0s;

                    &:hover {
                        color: #272338;
                    }
                }

                @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 20px;
                    margin: 8px 0 0;
                }
            }
        }
    }
}

//======= Modal CSS END =======

//======= Nested slider CSS Start =======
#parent-slider {
    > .splide__arrows {
        position: absolute;
        top: -54px;
        right: 0;
        display: flex;
        align-items: center;

        @media screen and (max-width: 767px) {
            top: -46px;
            right: -10px;
        }

        .splide__arrow {
            position: static;
            //transform: translateY(-0%);
            transform: none;
            z-index: 2;
            background-color: transparent;
            border: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 42px;
            height: 42px;
            outline: none;

            &:disabled {
                opacity: 0.3;
                cursor: not-allowed;
            }

            svg {
                display: none;
            }

            &.splide__arrow--prev {
                background-image: url(/assets/images/arrow-left-42.svg);
            }

            &.splide__arrow--next {
                background-image: url(/assets/images/arrow-right-42.svg);
            }
        }
    }

    .featured-image-slider {
        .splide__arrows {
            position: static;
        }

        .splide__arrow {
            position: absolute;
            transform: translateY(-50%);
            z-index: 2;
            background-color: #FFFFFF;
            border: 1px solid #272338;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            width: 24px;
            height: 24px;

            &:hover {
                background-color: #A9BEFF;
            }
        }
    }


    #parent-slider-list {
        > .splide__slide {
            @media screen and (max-width: 1024px) {
                width: 24.7% !important;
            }

            @media screen and (max-width: 991px) {
                width: 33% !important;
            }

            @media screen and (max-width: 767px) {
                width: 49.7% !important;
            }

            @media screen and (max-width: 480px) {
                width: 78% !important;
            }
        }
    }

    @media screen and (max-width: 991px) {
        .splide__track {
            padding: 0 10px !important;
            margin: 0 -16px;
        }
    }
}

#filterList {
    .splide__arrows {
        .splide__arrow {
            background: transparent;
            top: 50%;

            &.splide__arrow--prev {
                left: -30px;
            }

            &.splide__arrow--next {
                right: -30px;
            }
        }
    }
}

//======= Nested slider CSS END =======

//======= Back button CSS Start =======
.back-btn {
    padding: 14px 0;

    button,
    a {
        font-family: Aeonik, sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: #272338;
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        border-radius: 0;
        gap: 4px;
        align-items: center;
        display: inline-flex;
        cursor: pointer;
        text-decoration: none;

        img {
            transition: all 0.25s ease 0s;
            position: relative;
            left: 0;
        }

        &:hover {
            img {
                left: -3px;
            }
        }
    }
}

//======= Back button CSS END =======

//======= Sign-up and Log-in Section CSS Start =======
.enquire-cta {
    padding: 32px 0;
    background: #661735;
    border-top: 1px solid #272338;

    .enquire-cta-info {
        gap: 24px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        display: flex;

        @media screen and (max-width: 991px) {
            text-align: center;
            flex-direction: column;
        }

        .section-semi-title {
            margin: 0;
            color: #FFFFFF;

            @media screen and (max-width: 991px) {
                font-size: 24px;
                line-height: 30px;
            }

            @media screen and (max-width: 767px) {
                font-size: 26px;
                line-height: 31px;
            }
        }

        .enquire-cta-action {
            display: flex;
            align-items: center;
            gap: 8px;

            .secondary-btn {
                border-color: transparent;
                color: #fff;
                background: transparent;
            }

            .secondary-btn {
                &:hover {
                    background: #F1F5FF;
                    color: #272338;
                    border-color: #A9BEFF;
                }
            }
        }
    }
}

//======= Sign-up and Log-in Section CSS End =======

//======= Gallery Section CSS Start =======
.lg-container {
    .lg-backdrop {
        background-color: #272338;
    }

    .lg-toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;

        @media screen and (max-width: 767px) {
            padding: 12px 16px;
        }

        .lg-close {
            color: #FFFFFF;
            display: flex;
            align-items: center;
            gap: 4px;
            height: auto;
            width: auto;
            padding: 0;
            float: none;
            position: relative;
            z-index: 2;

            @media screen and (max-width: 767px) {
                justify-content: center;
            }

            &::before {
                content: "";
                background-image: url("/assets/images/close-white-icon.svg");
                background-position: center center;
                background-repeat: no-repeat;
                width: 24px;
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 767px) {
                    background-image: url("/assets/images/back-circle-icon.svg");
                    width: 32px;
                    height: 32px;
                }
            }

            &::after {
                content: "Close";
                font-family: Aeonik, sans-serif;
                font-size: 14px;
                line-height: 18px;
                font-weight: 500;

                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
        }

        .lg-counter {
            font-family: Aeonik, sans-serif;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            color: #FFFFFF;
            padding: 0;
            height: auto;
            position: absolute;
            top: 19px;
            right: 0;
            left: 0;
            width: 110px;
            margin: auto;
            text-align: center;

            @media screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 16px;
                color: #272338;
                padding: 4px 12px;
                border: 1px solid #272338;
                border-radius: 30px;
                background: rgba(255, 255, 255, 0.8);
                position: relative;
                top: auto;
                right: auto;
                left: auto;
                width: auto;
                margin: 0 0 0 auto;
            }
        }
    }

    .lg-content {
        top: 56px !important;
        bottom: 56px !important;

        .lg-prev,
        .lg-next {
            background: #FFFFFF;
            border-radius: 16px;
            width: 40px;
            height: 40px;
            padding: 0;
            border: 1px solid #272338;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            color: #272338;
            transition: all 0.35s ease 0s;
            font-size: 16px;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: none;

            &:hover {
                background: #A9BEFF;
                color: #272338;
            }

            @media screen and (max-width: 767px) {
                border-radius: 32px;
                width: 32px;
                height: 32px;
            }
        }

        .lg-next {
            right: 24px;

            @media screen and (max-width: 767px) {
                right: 16px;
            }
        }

        .lg-prev {
            left: 24px;

            @media screen and (max-width: 767px) {
                left: 16px;
            }
        }

        .lg-image {
            border: 1px solid #272338;
            border-radius: 16px;
        }
    }
}

//======= Gallery Section CSS End =======

//======= Wedspiration Slider Section CSS Start =======
.wedspiration-featured-section {
    #parent-slider {
        @media screen and (max-width: 767px) {
            flex-direction: column-reverse;
            display: flex;
        }

        > .splide__arrows {
            top: -68px !important;

            @media screen and (max-width: 767px) {
                justify-content: center;
                position: static !important;
                top: auto !important;
                margin-top: 24px;
            }
        }
    }

    .card-main {
        &:hover {
            background: transparent;
            border-color: transparent;
        }
    }
}

//======= Wedspiration Slider Section CSS End =======


//======= Tooltip CSS Start =======
.tooltip {
    --bs-emphasis-color: rgba(39, 35, 56, 0.85);

    &.show {
        opacity: 1;
    }

    .tooltip-inner {
        padding: 8px 10px;
        border-radius: 16px;
        font-size: 14px;
        line-height: 20px;
        backdrop-filter: blur(5px);
    }
}

//======= Tooltip CSS Start =======

//======= Toastr CSS Start =======
.toast-container {
    position: fixed;

    .ngx-toastr {
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #fff;
        border: 1px solid #272338;
        border-radius: 16px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15) !important;
        padding: 16px 36px 16px 52px;
        width: auto;
        max-width: 380px !important;
        margin: 0 0 8px;

        &:last-child {
            margin-bottom: 0;
        }

        @media screen and (max-width: 480px) {
            max-width: calc(100% - 24px) !important;
            margin: 0 0 8px auto;
        }

        .toast-close-button {
            background: transparent;
            width: 22px;
            height: 22px;
            display: inline-block;
            border-radius: 6px;
            opacity: 1;
            padding: 0;
            outline: none;
            transition: all 0.35s ease 0s;
            position: absolute;
            top: 6px;
            right: 6px;
            margin: 0;
            box-shadow: none;
            z-index: 1;

            span {
                display: none;
            }


            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                left: 0;
                transform: rotate(45deg);
                transition: all 0.35s ease 0s;
                background: #fff;
            }

            &:before {
                width: 14px;
                height: 2px
            }

            &:after {
                width: 2px;
                height: 14px;
            }

            &:hover {
                background: #272338;

                &:before,
                &:after {
                    background: #fff;
                }
            }
        }
    }

    .toast-info {
        //background-color: #272338;
        background-color: #0782AE;
    }

    .toast-error {
        background-color: #8D3636;
    }

    .toast-warning {
        background-color: #E2763A;
    }

    .toast-success {
        background-color: #34a853;
    }
}

//======= Toastr CSS END =======

//======= Blog Details - Content CSS Start =======
.blog-details-content {
    font-family: Aeonik, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #4D4D4D;
    flex: 1;
    word-break: break-word;

    p {
        margin: 0 0 24px;

        //&:last-child {
        //    margin-bottom: 0;
        //}

        a {
            font-weight: 500;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: Livorno, serif;
        font-weight: 400;
        color: #272338;
        letter-spacing: -0.01em;
        margin: 0 0 8px;

        * {
            font-family: Livorno, serif;
        }

        a {
            font-weight: normal;
        }

        span,
        strong,
        b {
            font-family: Livorno, serif;
            font-weight: normal;
        }
    }

    h2 {
        //font-size: 40px;
        //line-height: 48px;
        font-size: 32px;
        line-height: 38px;

        @media screen and (max-width: 991px) {
            font-size: 30px;
            line-height: 36px;
        }
    }

    h3 {
        //font-size: 32px;
        //line-height: 40px;
        font-size: 26px;
        line-height: 32px;
    }

    h4 {
        //font-size: 26px;
        //line-height: 34px;
        font-size: 22px;
        line-height: 28px;
    }

    h5 {
        font-size: 18px;
        line-height: 24px;
    }

    h6 {
        font-size: 16px;
        line-height: 22px;
    }

    a {
        color: #7093FF;
        transition: all 0.35s ease 0s;

        &:hover {
            color: #272338;
        }

        span, strong, b {
            transition: all 0.35s ease 0s;
            color: inherit;

            &:hover {
                color: #272338;
            }
        }
    }

    strong,
    b {
        font-weight: 500;
        color: #272338;
    }

    .blog-details-image {
        border-radius: 16px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 24px;
        gap: 16px;
        display: grid;

        img {
            width: 100%;
            //height: auto;
            height: 100%;
            max-width: 100%;
            max-height: 800px;
            border: 1px solid #272338;
            border-radius: 16px;
            vertical-align: middle;
            object-fit: cover;
        }

        @media screen and (min-width: 767px) {
            &:has(img:nth-child(2)) {
                grid-template-columns: repeat(2, 1fr);
            }

            &:not(:has(img:nth-child(2))) {
                grid-template-columns: 1fr;
            }
        }

        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    ul,
    ol {
        margin: 0 0 24px;
        padding: 0;
        list-style-type: none;

        //&:last-child {
        //    margin-bottom: 0;
        //}

        > li {
            position: relative;
            margin-bottom: 12px;

            &:last-child {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            ol,
            ul {
                margin: 12px 0;
            }
        }
    }

    ul {
        > li {
            padding-left: 26px;

            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: 2px;
                width: 12px;
                height: 12px;
                display: inline-block;
                background: #272338;
                transform: rotate(45deg);
                border-radius: 2px;
            }

            &::after {
                content: "";
                position: absolute;
                top: 8px;
                left: 5px;
                width: 6px;
                height: 6px;
                border-radius: 10px;
                background: #FFFBF8;
                display: inline-block;
            }
        }
    }

    ol {
        counter-reset: li;

        > li {
            padding-left: 32px;

            &::before {
                content: "";
                position: absolute;
                top: 4px;
                left: 2px;
                width: 16px;
                height: 16px;
                display: inline-block;
                background: #272338;
                transform: rotate(45deg);
                border-radius: 2px;
                pointer-events: none;
            }

            &::after {
                content: counter(li);
                counter-increment: li;
                position: absolute;
                top: 6px;
                left: 2px;
                width: 16px;
                display: inline-block;
                font-size: 11px;
                line-height: 12px;
                font-weight: 500;
                color: #fff;
                text-align: center;
                height: auto;
                background: transparent;
                border-radius: 0;
                pointer-events: none;
            }
        }
    }

    .credits-box {
        margin: 24px 0 0;
        padding: 32px 24px;
        border: 1px solid #272338;
        border-radius: 16px;
        font-family: Aeonik, sans-serif;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        color: #272338;
        gap: 32px;
        display: flex;

        @media screen and (max-width: 991px) {
            padding: 16px;
            flex-direction: column;
            gap: 8px;
        }

        a {
            color: #6C92C7;
            font-weight: 500;

            &:hover {
                color: #7093FF;
                text-decoration: underline;
            }
        }

        .credits-title {
            font-family: Aeonik, sans-serif;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: #272338;
            text-transform: uppercase;
            writing-mode: vertical-lr;
            transform: scale(-1);
            text-align: center;

            @media screen and (max-width: 991px) {
                writing-mode: unset;
                transform: none;
                text-align: left;
            }
        }

        ul {
            gap: 4px 16px;
            flex-wrap: wrap;
            display: flex;
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                margin: 0;
                padding: 0;
                list-style-type: none;

                &:before,
                &:after {
                    display: none;
                }
            }
        }

        label {
            margin: 0;
            padding-right: 8px;
            position: relative;
            display: inline-block;

            &:before {
                content: '';
                width: 2px;
                height: 2px;
                background: #999999;
                border-radius: 10px;
                position: absolute;
                top: 0;
                right: 3px;
                bottom: 0;
                margin: auto;
            }
        }
    }
}

//======= Blog Details - Content CSS END =======

//======= Loader CSS Start =======
.ng-loader {
    margin: 0;

    .backdrop {
        backdrop-filter: blur(5px);
    }
}

//======= Loader CSS END =======

//======= FAQ's Editor CSS Start =======
.faqs-wrap, .questions-answered {
    .accordion-item {
        .accordion-body {
            h2, h3, h4, h5, h6 {
                font-family: Livorno, serif;
                font-weight: 400;
                color: #272338;
                letter-spacing: -0.01em;
                margin: 0 0 12px;

                * {
                    font-family: Livorno, serif;
                }

                a {
                    font-weight: normal;
                }

                span, strong, b {
                    font-family: Livorno, serif;
                    font-weight: normal;
                }
            }

            h2 {
                font-size: 22px;
                line-height: 28px;
            }

            h3 {
                font-size: 18px;
                line-height: 24px;
            }

            h4 {
                font-size: 16px;
                line-height: 22px;
            }

            a {
                color: #7093FF;
                text-decoration: none;
                transition: all 0.35s ease 0s;

                &:hover {
                    color: #272338;
                }
            }

            strong, b {
                font-weight: 500;
            }

            p {
                margin: 0 0 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:empty {
                    display: none;
                }
            }

            ul, ol {
                margin: 0 0 12px;
                padding: 0;
                list-style-type: none;

                &:last-child {
                    margin-bottom: 0;
                }

                > li {
                    position: relative;
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    ol, ul {
                        margin: 12px 0;
                    }
                }
            }

            ul {
                > li {
                    padding-left: 26px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 2px;
                        width: 12px;
                        height: 12px;
                        display: inline-block;
                        background: #272338;
                        transform: rotate(45deg);
                        border-radius: 2px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        top: 8px;
                        left: 5px;
                        width: 6px;
                        height: 6px;
                        border-radius: 10px;
                        background: #FFFBF8;
                        display: inline-block;
                    }
                }
            }

            ol {
                counter-reset: li;

                > li {
                    padding-left: 32px;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        left: 2px;
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                        background: #272338;
                        transform: rotate(45deg);
                        border-radius: 2px;
                        pointer-events: none;
                    }

                    &::after {
                        content: counter(li);
                        counter-increment: li;
                        position: absolute;
                        top: 6px;
                        left: 2px;
                        width: 16px;
                        display: inline-block;
                        font-size: 11px;
                        line-height: 12px;
                        font-weight: 500;
                        color: #fff;
                        text-align: center;
                        height: auto;
                        background: transparent;
                        border-radius: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.questions-answered {
    &.couples-faqs {
        .accordion-item {
            .accordion-body {
                a {
                    &:hover {
                        color: #fff;
                    }
                }

                ul {
                    > li {

                        &::before {
                            background: #FFFBF8;
                        }

                        &::after {
                            background: #272338;
                        }
                    }
                }

                ol {
                    > li {
                        &::before {
                            background: #fff;
                        }

                        &::after {
                            font-weight: 700;
                            color: #272338;
                        }
                    }
                }
            }
        }
    }
}

//======= FAQ's Editor CSS END =======
